import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Warcraft',
    // redirect: '/firstpage',
    meta:{
      name:'Warcraft',
      title: '魔兽争霸',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },// 魔兽争霸
  {
    path: '/warcraft',
    name: 'Warcraft',
    meta:{
      name:'Warcraft',
      title: '魔兽争霸',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 魔兽争霸
  {
    path: '/warcrafti',
    name: 'Warcrafti',
    meta:{
      name:'Warcrafti',
      title: '魔兽争霸',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 魔兽争霸攻略包
  {
    path: '/redAlert',
    name: 'RedAlert',
    meta:{
      name:'RedAlert',
      title: '红警',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 红警
  {
    path: '/RedAlerti',
    name: 'RedAlerti',
    meta:{
      name:'RedAlerti',
      title: '红警',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 红警攻略包
  {
    path: '/Acgame',
    name: 'Acgame',
    meta:{
      name:'Acgame',
      title: '街机合集',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 街机
  {
    path: '/Acgamei',
    name: 'Acgamei',
    meta:{
      name:'Acgamei',
      title: '街机合集',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 街机攻略包
  {
    path: '/HoMM',
    name: 'HoMM',
    meta:{
      name:'HoMM',
      title: '英雄无敌3',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/otherView.vue')
  },// 英雄无敌
  {
    path: '/HoMMi',
    name: 'HoMMi',
    meta:{
      name:'HoMMi',
      title: '英雄无敌3',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/otherView.vue')
  },// 英雄无敌攻略包
  {
    path: '/CounterStrike',
    name: 'CounterStrike',
    meta:{
      name:'CounterStrike',
      title: '反恐精英',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 反恐精英
  
  {
    path: '/CounterStrikei',
    name: 'CounterStrikei',
    meta:{
      name:'CounterStrikei',
      title: '反恐精英',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 反恐精英攻略包
  {
    path: '/StarCraft',
    name: 'StarCraft',
    meta:{
      name:'StarCraft',
      title: '星际争霸',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 星际争霸
  
  {
    path: '/StarCrafti',
    name: 'StarCrafti',
    meta:{
      name:'StarCrafti',
      title: '星际争霸',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 星际争霸攻略包
  {
    path: '/Diablo',
    name: 'Diablo',
    meta:{
      name:'Diablo',
      title: '暗黑破坏神',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/otherView.vue')
  }, // 暗黑破坏神
  {
    path: '/Diabloi',
    name: 'Diabloi',
    meta:{
      name:'Diabloi',
      title: '暗黑破坏神',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/otherView.vue')
  }, // 暗黑破坏神攻略包
  {
    path: '/about',
    name: 'About',
    meta:{
      name:'About',
      title: '游戏安装助手',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/dllAbout',
    name: 'DllAbout',
    meta:{
      name:'DllAbout',
      title: '修复工具安装助手',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/dllAbout.vue')
  },
  {
    path: '/strategyAbout',
    name: 'strategyAbout',
    meta:{
      name:'strategyAbout',
      title: '攻略包安装助手',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/strategyAbout.vue')
  },
  {
    path: '/xiangqi',
    name: 'chaineseChess',
    meta:{
      name:'chaineseChess',
      title: '象棋安装中心',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/xiangqi/xiangqi.vue')
  },
  {
    path: '/QiangTan',
    name: 'QiangTanDengLu',
    meta:{
      name:'QiangTanDengLu',
      title: '抢滩登陆战',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 抢滩登陆战,
  {
    path: '/QiangTanDengLui',
    name: 'QiangTanDengLui',
    meta:{
      name:'QiangTanDengLui',
      title: '抢滩登陆战',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 抢滩登陆战攻略包,
  {
    path: '/XueZhan',
    name: 'XueZhanShangHaiTan',
    meta:{
      name:'XueZhanShangHaiTan',
      title: '血战上海滩',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 血战上海滩
  
  {
    path: '/XueZhanShangHaiTani',
    name: 'XueZhanShangHaiTani',
    meta:{
      name:'XueZhanShangHaiTani',
      title: '血战上海滩',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 血战上海滩攻略包
  {
    path: '/DiGuo',
    name: 'DiGuo',
    meta:{
      name:'DiGuo',
      title: '帝国时代',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 帝国时代
  {
    path: '/JingDianHeJi',
    name: 'JingDianHeJi',
    meta:{
      name:'JingDianHeJi',
      title: '经典游戏合集',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // Windows游戏经典合集
  {
    path: '/JingDianHeJii',
    name: 'JingDianHeJii',
    meta:{
      name:'JingDianHeJii',
      title: '经典游戏合集',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // Windows游戏经典合集攻略包
  {
    path: '/BaoLiMoTuo',
    name: 'BaoLiMoTuo',
    meta:{
      name:'BaoLiMoTuo',
      title: '暴力摩托',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 暴力摩托
  {
    path: '/BaoLiMoTuoi',
    name: 'BaoLiMoTuoi',
    meta:{
      name:'BaoLiMoTuoi',
      title: '暴力摩托',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 暴力摩托攻略包
  {
    path: '/ZuMa',
    name: 'ZuMa',
    meta:{
      name:'ZuMa',
      title: '祖玛',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 祖玛
  {
    path: '/ZuMai',
    name: 'ZuMai',
    meta:{
      name:'ZuMai',
      title: '祖玛',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 祖玛攻略包
  {
    path: '/ShiMingZhaoHuan',
    name: 'ShiMingZhaoHuan',
    meta:{
      name:'ShiMingZhaoHuan',
      title: '使命召唤',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 使命召唤
  {
    path: '/ShiMingZhaoHuani',
    name: 'ShiMingZhaoHuani',
    meta:{
      name:'ShiMingZhaoHuani',
      title: '使命召唤',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 使命召唤攻略包
  {
    path: '/GuiQi',
    name: 'GuiQi',
    meta:{
      name:'GuiQi',
      title: '鬼泣',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 鬼泣
  {
    path: '/GuiQii',
    name: 'GuiQii',
    meta:{
      name:'GuiQii',
      title: '鬼泣',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }, // 鬼泣攻略包
  {
    path: '/MountBlade',
    name: 'MountBlade',
    meta:{
      name:'MountBlade',
      title: '骑马与砍杀',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/otherView.vue')
  },// 骑马与砍杀
  {
    path: '/MountBladei',
    name: 'MountBladei',
    meta:{
      name:'MountBladei',
      title: '骑马与砍杀',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/otherView.vue')
  },// 骑马与砍杀攻略包
  {
    path: '/Prototype',
    name: 'Prototype',
    meta:{
      name:'Prototype',
      title: '虐杀原形',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 虐杀原形
  {
    path: '/Prototypei',
    name: 'Prototypei',
    meta:{
      name:'Prototypei',
      title: '虐杀原形',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 虐杀原攻略包
  {
    path: '/GTA',
    name: 'GTA',
    meta:{
      name:'GTA',
      title: '侠盗猎车手',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 侠盗猎车手
  {
    path: '/GTAi',
    name: 'GTAi',
    meta:{
      name:'GTAi',
      title: '侠盗猎车手',
      strategy: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 侠盗猎车手攻略包
  {
    path: '/dll',
    name: 'dll',
    meta:{
      name:'dll',
      title: '修复工具',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/dllView.vue')
  },// 工具
  {
    path: '/msvbcrt',
    name: 'msvbcrt',
    meta:{
      name:'msvbcrt',
      title: '修复工具',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/msvbcrtView.vue')
  },// 工具
  {
    path: '/ResidentEvil',
    name: 'ResidentEvil',
    meta:{
      name:'ResidentEvil',
      title: '生化危机',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 生化危机
  {
    path: '/ResidentEvili',
    name: 'ResidentEvili',
    meta:{
      name:'ResidentEvili',
      title: '生化危机',
      strategy: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 生化危机攻略包
  // {
  //   path: '/youxi',
  //   name: 'youxi',
  //   meta:{
  //     name:'',
  //     title: '游戏安装助手',
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/software/software.vue')
  // },
  // {
  //   path: '/gameAbout',
  //   name: 'gameAbout',
  //   meta:{
  //     name:'',
  //     title: 'gameAbout',
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/software/about.vue')
  // },
  // {
  //   path: '/gameLxus',
  //   name: 'gameLxus',
  //   meta:{
  //     name:'',
  //     title: 'gameLxus',
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/software/lxUs.vue')
  // },
  {
    path: '/pdf',
    name: 'pdf',
    meta:{
      name:'',
      title: '万年历',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/tool/tool.vue')
  },
  {
    path: '/isAbout',
    name: 'isAbout',
    meta:{
      name:'',
      title: 'isAbout',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/isAbout.vue')
  },
  {
    path: '/LxUs',
    name: 'LxUs',
    meta:{
      name:'',
      title: 'LxUs',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/LxUs.vue')
  },
  {
    path: '/MORBID',
    name: 'Morbid',
    meta:{
      name:'Morbid',
      title: '病态：暴怒领主',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newGames.vue')
  },
  {
    path: '/ChronoArk',
    name: 'ChronoArk',
    meta:{
      name:'ChronoArk',
      title: '超时空方舟',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newGames.vue')
  },
  {
    path: '/Hades',
    name: 'Hades',
    meta:{
      name:'Hades',
      title: '哈迪斯',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newGames.vue')
  },
  {
    path: '/Palworld',
    name: 'Palworld',
    meta:{
      name:'Palworld',
      title: '幻兽帕鲁',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newGames.vue')
  },
  {
    path: '/DAVE',
    name: 'DAVE',
    meta:{
      name:'DAVE',
      title: '潜水员戴夫',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newGames.vue')
  },
  {
    path: '/CIVILIZATION',
    name: 'SMCVI',
    meta:{
      name:'SMCVI',
      title: '文明6',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newGames.vue')
  },
  {
    path: '/IFZ',
    name: 'IFZ',
    meta:{
      name:'IFZ',
      title: '无感染区',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newGames.vue')
  },
  {
    path: '/Crab',
    name: 'ACT',
    meta:{
      name:'ACT',
      title: '蟹蟹寻宝奇遇',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newGames.vue')
  },
  {
    path: '/Stronghold',
    name: 'Stronghold',
    meta:{
      name:'Stronghold',
      title: '要塞：决定版',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newGames.vue')
  },
  {
    path: '/ManorLords',
    name: 'ManorLords',
    meta:{
      name:'ManorLords',
      title: '庄园领主',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newGames.vue')
  },
  {
    path: '/Bellwright',
    name: 'Bellwright',
    meta:{
      name:'Bellwright',
      title: '颂钟长鸣',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newGames.vue')
  },
  {
    path: '/Wicked',
    name: 'Wicked',
    meta:{
      name:'Wicked',
      title: '恶意不息',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newGames.vue')
  },
]


const router = new VueRouter({
  mode: 'history',
  // base: '/web',
  routes
})

router.afterEach((to, from) => {
  // var favIco = document.getElementById('favicon')
  // var title = document.getElementById('docTitle')
  // if ( window.location.host == 'youxi.ncdsnc.com' ||  window.location.host == 'yx.yx8668.com' ) {
  //   favIco.href = './favico.ico'
  //   document.title = "404 Not Found";
  //   document.body.innerHTML = `<center><h1>404 Not Found</h1></center>
  //       <hr><center>nginx</center>`;
  // } else {
  //   if (to.meta.name) {
  //     favIco.href = '/static/' + to.meta.name + '.ico'
  //     title.innerText =  to.meta.title
  //   } else {
  //     favIco.href = './favico.ico'
  //     if ( to.meta.title ) {
  //       title.innerText =  to.meta.title
  //     } else {
  //       document.title = "404 Not Found";
  //       document.body.innerHTML = `<center><h1>404 Not Found</h1></center>
  //       <hr><center>nginx</center>`;
  //     }
  // }
  // }
})


export default router
