<template>
  <div class="mask" v-if="isShow" v-hmd-mask>
    <div class="confirm-padding container" v-hmd-popup v-loading="isloading">
      <div class="flex flex-justifyContent-spaceBetween">
        <div>{{ config.cancelText || '取消' }}</div>
        <div class="close" @click="close"></div>
      </div>
      <div class="confirm_xian"></div>
      <!-- <div class="conmon_fontDesc mar-top-40">请写下您的优化建议，如若遇到问题需要帮助，请联系 <span @click="click_qq">QQ:1446225604</span></div> -->
      <div class="conmon_fontDesc mar-top-40">请写下您的优化建议，如若遇到问题需要帮助，请联系在线客服处理</div>
      <el-input ref="textarea" @focus="loseFocus('.el-textarea__inner')" class="textarea" resize="none" autofocus
        type="textarea" placeholder="留下您的建议或者遇到的问题" v-model="text" maxlength="200" show-word-limit></el-input>
      <div class="conmon_fontDesc mar-top-24">联系方式</div>
      <el-input ref="phone" maxlength="20" @focus="loseFocus('.el-input__inner')" v-model="phone"
        placeholder="请输入您的联系方式（手机、邮箱、微信、QQ)"></el-input>
      <div class="flex flex-justifyContent-spaceBetween mar-top-50">
        <div></div>
        <div class="flex">
          <div class="cancel-btn button" v-if="config.isShowcancelbtn" @click="cancelFn">{{ config.cancelbtnText ||
            '取消'}}</div>
          <div class="confirm-btn button" v-if="config.isShowconfirmbtn" @click="confirmFn">{{ config.confirmbtnText ||
            '确认'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from './mixin'
import preventPageScroll from './prevent-page-scroll'
import s from './selector'
export default {
  mixins: [mixin],
  data() {
    return {
      isShow: true,
      text: '',
      phone: '',
      config: {},
      isloading: false,
    }
  },
  directives: {
    "hmd-mask": {
      bind: function (el) {
        /* 这个做寻找的时候的标记量 */
        el.classList.add("wc-messagebox__mask---");
        /* init */
        el.classList.add("wc-messagebox__mask-transition---");
        el.classList.add("wc-messagebox__mask-diff---");
        /* 移除以触发变化*/
        setTimeout(() => {
          el.classList.remove("wc-messagebox__mask-diff---");
        }, 50);
      }
    },
    "hmd-popup": {
      bind: function (el) {
        /* 这个做寻找的时候的标记量 */
        el.classList.add("wc-messagebox__popup---");
        /* init */
        el.classList.add("wc-messagebox__popup-transition---");
        el.classList.add("wc-messagebox__popup-diff---");
        /* 移除以触发变化*/
        setTimeout(() => {
          el.classList.remove("wc-messagebox__popup-diff---");
        }, 50);
      }
    }
  },
  mounted() {
    preventPageScroll.prevent()
  },
  methods: {
    //反馈
    async feedback() {
      this.isloading = true
      setTimeout(() => {
        this.$message.success('提交成功')
        this.isloading = false
        setTimeout(() => {
          this.hideDom()
        }, 200);
      }, 2000);
      // const { code,message } = await this.$PostPro('/user/report',{ content: this.text,contact: this.phone },true)
      // if (code == '200') {
      //   this.$message.success('提交成功')
      // } else if (code == '401') {
      //   this.$message.error(message)
      // } else {
      //   this.$message.error('提交失败！')
      // }
    },
    loseFocus(classname) {
      document.querySelector(classname).style.border = 'none'
    },
    close() {
      preventPageScroll.recover();
      /* 先要隐藏掉 mask 和 alert */
      s(".wc-messagebox__mask---").classList.add("wc-messagebox__mask-diff---");
      /* 这里 popup 放的是 mask-diff */
      s(".wc-messagebox__popup---").classList.add("wc-messagebox__popup-leave---");
      /* 在动画结束之后, 删掉元素 */
      s(".wc-messagebox__popup---").addEventListener(
        "transitionend",
        () => {
          this.isShow = false;
        },
        false
      );

      this.config.close && this.config.close.apply();
    },
    cancelFn() {
      preventPageScroll.recover();
      /* 先要隐藏掉 mask 和 alert */
      s(".wc-messagebox__mask---").classList.add("wc-messagebox__mask-diff---");
      /* 这里 popup 放的是 mask-diff */
      s(".wc-messagebox__popup---").classList.add("wc-messagebox__popup-leave---");
      /* 在动画结束之后, 删掉元素 */
      s(".wc-messagebox__popup---").addEventListener(
        "transitionend",
        () => {
          this.isShow = false;
        },
        false
      );
      this.config.cancelFn && this.config.cancelFn.apply();
    },
    confirmFn() {
      preventPageScroll.recover();
      if (!this.text) {
        document.querySelector('.el-textarea__inner').style.border = '1px solid red'
      }
      if (!this.phone) {
        document.querySelector('.el-input__inner').style.border = '1px solid red'
      }
      if (this.text && this.phone) {
        this.feedback()
        // this.config.confirmFn && this.config.confirmFn.apply();
      }
    },

    hideDom() {
      /* 先要隐藏掉 mask 和 alert */
      s(".wc-messagebox__mask---").classList.add("wc-messagebox__mask-diff---");
      /* 这里 popup 放的是 mask-diff */
      s(".wc-messagebox__popup---").classList.add("wc-messagebox__popup-leave---");
      /* 在动画结束之后, 删掉元素 */
      s(".wc-messagebox__popup---").addEventListener(
        "transitionend",
        () => {
          this.isShow = false;
        },
        false
      );
    },
    click_qq() {
      window.open('https://wpa.qq.com/msgrd?v=3&uin=1446225604&site=qq&menu=yes')
    }
  }
}
</script>

<style scoped>
@import url('./confirm.css');
@import url('./transition.css');
</style>