let preventPageScroll = e => {
    return e.preventDefault();
};
// 移动端做适配处理，pc端可有可无
export default {
    prevent() {
        document.addEventListener('touchmove',preventPageScroll,false);
    },
    recover() {
        document.removeEventListener('touchmove',preventPageScroll,false);
    }
}
