<template>
  <div id="app" ref="cont">
      <!-- <allHead></allHead> -->
      <router-view v-if="isShow" />
      <div class="foot_nav_fixed">
        <div class="is_all_box" @click="openKefu">
          <div class="footer_img">
            <img src="./assets/game/ic_service.svg" alt="" class="icon_content_width">
          </div>
          <div class="footer_scale_font">在线客服</div>
        </div>
        <div class="is_all_box" @click="openKefu">
          <div class="footer_img">
            <img src="./assets/game/ic_qq.svg" alt="" class="icon_content_width">
          </div>
          <div class="footer_scale_font">QQ客服</div>
        </div>
        <div class="is_all_box" @click="feedback">
          <div class="footer_img">
            <img src="./assets/game/ic_opinion.svg" alt="" class="icon_content_width">
          </div>
          <div class="footer_scale_font">意见反馈</div>
        </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  // import allHead from './views/all_head.vue';
  export default {
    name: 'App',
    components: {
      // allHead,
    },
    data(){
      return {
        bdVidList: [
          { host: 'localhost:8080', type: 1 },
        ],
        qhclickidList: [
          { host: 'localhost:8080', type: 1 }
        ],
        clickBtuuonRepeat: false,
        width: window.innerWidth,
        height: window.innerHeight, //window.innerHeight,
        isShow: false,
        isRes: false,

        goRequsetArrHost:[
          'ggzoom.cn',
          'cqygfxgfst.top',
          // 'game.sbwwl.top'
        ]
      }
    }, 
    watch:{
      $route:{
        handler: function () {
          setTimeout(() => {
            if ( this.$route.name != 'About' ) {
              this.track(0,'','')
            }
            if ( ['dll','msvbcrt','About','DllAbout','chaineseChess','youxi','gameAbout','gameLxus','pdf','isAbout','LxUs'].includes(this.$route.name) ) {
              // if ( !this.isRes ) {
              //   this.isShow = false
              //   this.changeTitle()
              // } else {
              //   this.isShow = true
              //   // this.getIco()
              // }
              this.isShow = true
            }
            this.getTime()
          }, 20);
        },
        deep:true,
        // immediate:true
      }
    },
    mounted(){
      console.log(this.$route,'asd');
      // this.getTime()
      let res = this.parseQueryString(window.location.href)
      sessionStorage.setItem('lunchUrl', window.location.href)
      if (res && res.bd_vid) {
        sessionStorage.setItem('tg_id', res.bd_vid)
        sessionStorage.setItem('tg_type', 1)
        let bd_type = this.bdVidList.find((item) => item.host == window.location.host)
        sessionStorage.setItem('tg_url_type', bd_type ? bd_type.type : '')
      } else {
        let bd = sessionStorage.getItem('tg_id')
        let bt = sessionStorage.getItem('tg_url_type')
        let qh = sessionStorage.getItem('tg_type')
        sessionStorage.setItem('tg_id', bd)
        sessionStorage.setItem('tg_url_type', bt)
        sessionStorage.setItem('tg_type', qh)
      }

      if (res && res.qhclickid) {
        sessionStorage.setItem('tg_id', res.qhclickid)
        sessionStorage.setItem('tg_type', 2)
        let qhclickid_type = this.qhclickidList.find((item) => item.host == window.location.host)
        sessionStorage.setItem('tg_url_type', qhclickid_type ? qhclickid_type.type : '')
      } else {
        let bd = sessionStorage.getItem('qhclickid')
        let qh = sessionStorage.getItem('tg_type')
        let bt = sessionStorage.getItem('tg_url_type')
        sessionStorage.setItem('qhclickid', bd)
        sessionStorage.setItem('tg_url_type', bt)
        sessionStorage.setItem('tg_type', qh)
      }
      if (res && res.id) {
        sessionStorage.setItem('pdf_id', res.id ? res.id : '')
      } else {
        let id = sessionStorage.getItem('pdf_id')
        sessionStorage.setItem('pdf_id', id)
      }
      window.addEventListener('resize', () => {
        this.updateScaleRatio()
      })
      this.updateScaleRatio()
      // this.toUrl()
    },
    methods:{
      feedback(){
        this.$popup({
          cancelText: '问题反馈',
          isShowcancelbtn: true,
          isShowconfirmbtn: true
        })
      },
      openKefu(){
        window.open('https://ahzmxxkjyxgsn7.qiyukf.com/client?k=e03981bea39111336753379e69b69b9d&wp=1&robotShuntSwitch=0&gid=484309424')
      },

      getIco(){
        var favIco = document.getElementById('favicon')
        var title = document.getElementById('docTitle')
        if ( window.location.host == 'youxi.ncdsnc.com') {
          favIco.href = './favico.ico'
          document.title = "404 Not Found";
          document.body.innerHTML = `<center><h1>404 Not Found</h1></center>
              <hr><center>nginx</center>`;
        } else {
          if (this.$route.meta.name) {
            favIco.href = '/static/' + this.$route.meta.name + '.ico'
            title.innerText =  this.$route.meta.title
          } else {
              favIco.href = './favico.ico'
              if ( this.$route.meta.title ) {
                title.innerText =  this.$route.meta.title
              } else {
                document.title = "404 Not Found";
                document.body.innerHTML = `<center><h1>404 Not Found</h1></center>
                <hr><center>nginx</center>`;
              }
          }
        }
      },

      changeTitle(){
        var favIco = document.getElementById('favicon')
        favIco.href = './favico.ico'
        document.title = "404 Not Found";
        document.body.innerHTML = `<center><h1>404 Not Found</h1></center>
        <hr><center>nginx</center>`;
      },

      toUrl(){
        const currentUrl = ['danji.wxqhx.cn','youxi.wxqhx.cn','danji.ggzoom.cn','danji.cqygfxgfst.top','danji.jjdpkh.cn','youxi.wxiskj.cn','youxi.ncdsnc.com','localhost:8080']
        let isUrl = window.location.host
        if ( !currentUrl.includes(isUrl) ) {

          window.top.location.href = 'http://danji.ncdsnc.com/redAlert?id=3'
        }
      },

      getTime(){
        var isConnect = false
        var currentHost = window.location.host
        this.goRequsetArrHost.forEach(item =>{
          if (currentHost.indexOf(item) != -1) {
            isConnect = true
          }
        })
        if ( isConnect ) {
          const url =`${process.env.VUE_APP_BASE_API_DOMAIN}/api/v1/get_time`
          axios(url,{}).then(res =>{
            if ( window.location.host == 'youxi.ncdsnc.com') {
              this.isShow = false
              this.isRes = true
              this.changeTitle()
              return
            } else {
              if (!res.data.data.res) {
                this.isShow = false
                this.isRes = false
                this.changeTitle()
              } else {
                this.getIco()
                this.isShow = true
                this.isRes = true
              }
            }
          }).catch(() =>{
            if ( window.location.host == 'youxi.ncdsnc.com') {
              this.isShow = false
              this.isRes = true
              this.changeTitle()
              return
            } else {
              this.getIco()
              this.isShow = true
              this.isRes = true
            }
          })
        } else {
          if ( window.location.host == 'youxi.ncdsnc.com') {
              this.isShow = false
              this.isRes = true
              this.changeTitle()
              return
            } else {
              this.getIco()
              console.log('1233');
              this.isShow = true
              this.isRes = true
            }
        }
      },

      toDownload(){
        let res = sessionStorage.getItem('downloadUrl') || ''
        if(res) {
          if(this.clickBtuuonRepeat) return
          const a = document.createElement('a')
          a.href = res
          document.body.appendChild(a)
          a.click()
          a.remove()
        }
        let isCallbackurl = ''
          isCallbackurl = 'https://api.pdf.kafanol.com/pdf/download/callback'
        axios.post(isCallbackurl,{ tg_id: sessionStorage.getItem('tg_id') == 'null' ? '0' : sessionStorage.getItem('tg_id') }).then((res) => {
          console.log(res);
        })
        this.clickBtuuonRepeat = true
        setTimeout(() => {
          this.clickBtuuonRepeat = false
        }, 2000)
      },
      //将url转成对象
      parseQueryString(url) {
        //url参数转对象
        url = !url ? window.location.href : url
        if (url.indexOf('?') === -1) {
          return null
        }
        let search = url[0] === '?' ? url.substr(1) : url.substring(url.lastIndexOf('?') + 1)
        if (search === '') {
          return null
        }
        search = search.split('&')
        let query = {}
        for (let i = 0; i < search.length; i++) {
          let pair = search[i].split('=')
          query[pair[0]] = pair[1] || ''
        }
        return query
      },

      updateScaleRatio() {
        var rect = this.$refs.cont && this.$refs.cont.getBoundingClientRect()
        var ratio = rect.width / rect.height
        var innerRatio = this.width / this.height
        if (ratio < innerRatio) {
          var scaleRatio = rect.width / this.width
          this.currentRatio = scaleRatio >= 1 ? 1 : scaleRatio
        } else {
          scaleRatio = rect.height / this.height
          this.currentRatio = scaleRatio
          this.currentRatio = scaleRatio >= 1 ? 1 : scaleRatio
        }
      },
    }
  }
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 14px;
  background-color: #000;
  // max-width: 2285px;
  // height: 100vh;
}

:root {
  --base_color: #FB4869;
  --base_width: 1184px;
  --base_black: #505361;
  --base_hover: #5e80f8;
  --base_active: #2a47af;
}

#app,
html,
body {
  width:100%;
  position: relative;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  background: #fff;
  padding: 0px;
  margin: 0px;
  min-width: var(--base_width);
}

.foot_nav_fixed {
  position: fixed;
  right: 0;
  top: 40%;
  z-index: 100;

  .is_all_box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  & > div {
    width: 80px;
    height: 80px;
    background: #fff;
    .footer_img {
      .icon_content_width {
        width: 34px;
        height: 34px;
        background-size: 100%;
      }

      img {
        width: 36px;
        height: 36px;
      }
    }
    .footer_scale_font {
      margin-top: 2px;
      font-size: 10px;
      transform: scale(0.8);
      // background: #ebebeb;
    }
  }
  & > div:hover {
    background: #ebebeb;
    cursor: pointer;
  }
}
</style>
