export default {
    mounted() {
        let listener = () => {
            this.isShow = false;
        };
        // 监听在弹出页面路由的改变，如果改变则隐藏弹框
        window.addEventListener("hashchange",listener);
        window.addEventListener("popstate",listener);
        window.addEventListener("pagehide",listener);
    },
}