
import Vue from 'vue';
import tpl from './confirm.vue'

let isShow = false;
let TPL = Vue.extend(tpl)

let Confirm = options => {
    if (isShow) return
    let instance = new TPL();
    instance.$data.config = options;
    instance.$data.isShow = true;
    //这行代码只正针对于移动端
    // [...document.querySelectorAll('input')].forEach(item => item.blur())
    document.body.appendChild(instance.$mount().$el);
}

export default {
    install(Vue) {
        Vue.prototype.$popup = Confirm
    }
}